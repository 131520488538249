export default {
  productCatalogueFilters: {
    filters: {
      rooms: [],
      brands: [],
      price: {
        from: '',
        to: '',
      },
      name: '',
      style: [],
      material: [],
      pattern: [],
      color: [],
      dimensions: '',
      category: [],
    },
    count: 0,
  },
  mxSeiveFilters: {
    filters: {
      rooms: [],
      brands: [],
      price: {
        from: '',
        to: '',
      },
      name: '',
      style: [],
      material: [],
      pattern: [],
      color: [],
      dimensions: '',
      category: [],
    },
  },
  status: {
    addToStudioStatus: null,
    addAllToStudioStatus: null,
  },

  productCataloguePageNo: {
    pageNo: 1,
  },
};
