import {
  ACCEPT_PROJECT_FAILED,
  ACCEPT_PROJECT_REQUESTED,
  ACCEPT_PROJECT_SUCCESS,
  INCOMING_PROJECT_LIST_FAILED,
  INCOMING_PROJECT_LIST_REQUESTED,
  INCOMING_PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAILED,
  PROJECT_LIST_REQUESTED,
  PROJECT_LIST_SUCCESS,
  REJECT_PROJECT_FAILED,
  REJECT_PROJECT_REQUESTED,
  REJECT_PROJECT_SUCCESS,
  REVIEW_SHARED_MOODBOARD_FAILED,
  REVIEW_SHARED_MOODBOARD_REQUESTED,
  REVIEW_SHARED_MOODBOARD_SUCCESS,
  SHARE_MOODBOARD_FAILED,
  SHARE_MOODBOARD_REQUESTED,
  SHARE_MOODBOARD_SUCCESS,
  SINGLE_PROJECT_FAILED,
  SINGLE_PROJECT_REQUESTED,
  SINGLE_PROJECT_SUCCESS,
  UPDATE_PROJECT_STATUS_FAILED,
  UPDATE_PROJECT_STATUS_REQUESTED,
  UPDATE_PROJECT_STATUS_SUCCESS
} from "../../../statusTypes";
import { showErrorToast } from "../../../../utility/showToast"
import initialState from "./initialState";

const projectReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state })

  switch (action.type) {
    //INCOMING PROJECTS
    case INCOMING_PROJECT_LIST_REQUESTED:
      nextState.incomingProjectListStatus = INCOMING_PROJECT_LIST_REQUESTED
      return nextState;
    case INCOMING_PROJECT_LIST_SUCCESS:
      nextState.incomingProjectListStatus = INCOMING_PROJECT_LIST_SUCCESS
      nextState.incomingProjects = action.data
      return nextState;
    case INCOMING_PROJECT_LIST_FAILED:
      nextState.incomingProjectListStatus = INCOMING_PROJECT_LIST_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //ALL PROJECT LIST
    case PROJECT_LIST_REQUESTED:
      nextState.listStatus = PROJECT_LIST_REQUESTED
      return nextState;
    case PROJECT_LIST_SUCCESS:
      nextState.listStatus = PROJECT_LIST_SUCCESS
      nextState.projects = action.data
      return nextState;
    case PROJECT_LIST_FAILED:
      nextState.listStatus = PROJECT_LIST_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //SINGLE PROJECT DETAILS
    case SINGLE_PROJECT_REQUESTED:
      nextState.detailStatus = SINGLE_PROJECT_REQUESTED
      return nextState;
    case SINGLE_PROJECT_SUCCESS:
      nextState.detailStatus = SINGLE_PROJECT_SUCCESS
      nextState.selectedProject = action.data
      return nextState;
    case SINGLE_PROJECT_FAILED:
      nextState.detailStatus = SINGLE_PROJECT_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //UPDATE PROJECT STATUS
    case UPDATE_PROJECT_STATUS_REQUESTED:
      nextState.updateStatus = UPDATE_PROJECT_STATUS_REQUESTED
      return nextState
    case UPDATE_PROJECT_STATUS_SUCCESS:
      nextState.updateStatus = UPDATE_PROJECT_STATUS_SUCCESS
      const projectIndex = nextState.incomingProjects.findIndex((e, index) => e._id === action.data.projectId)
      nextState.incomingProjects.splice(projectIndex, 1)
      nextState.incomingProjects = [...nextState.incomingProjects]
      return nextState
    case UPDATE_PROJECT_STATUS_FAILED:
      nextState.updateStatus = UPDATE_PROJECT_STATUS_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState

    //SHARE MOODBOARD AND UPDATE PROJECT STATUS
    case SHARE_MOODBOARD_REQUESTED:
      nextState.shareMoodboardStatus = SHARE_MOODBOARD_REQUESTED
      return nextState
    case SHARE_MOODBOARD_SUCCESS:
      nextState.shareMoodboardStatus = SHARE_MOODBOARD_SUCCESS
      nextState.selectedProject = action.data.project
      let projectsIndex = nextState.projects.findIndex((e, index) => e._id === action.data.project.id)
      nextState.projects[projectsIndex].status = action.data.project.status
      nextState.projects[projectsIndex].job = action.data.project.job
      nextState.projects[projectsIndex].customer_feedback = action.data.project.customer_feedback
      nextState.projects = [...nextState.projects]
      return nextState
    case SHARE_MOODBOARD_FAILED:
      nextState.shareMoodboardStatus = SHARE_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState

    //ACCEPT INCOMING PROJECT
    case ACCEPT_PROJECT_REQUESTED:
      nextState.acceptProjectStatus = ACCEPT_PROJECT_REQUESTED
      return nextState
    case ACCEPT_PROJECT_SUCCESS:
      nextState.acceptProjectStatus = ACCEPT_PROJECT_SUCCESS
      let removeIndex = nextState.incomingProjects.findIndex((e, index) => e.id === action.data.id)
      nextState.incomingProjects.splice(removeIndex, 1)
      nextState.incomingProjects = [...nextState.incomingProjects]
      nextState.projects = [...nextState.projects, action.data]

      return nextState
    case ACCEPT_PROJECT_FAILED:
      nextState.acceptProjectStatus = ACCEPT_PROJECT_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState

    //REJECT INCOMING PROJECT
    case REJECT_PROJECT_REQUESTED:
      nextState.rejectProjectStatus = REJECT_PROJECT_REQUESTED
      return nextState
    case REJECT_PROJECT_SUCCESS:
      nextState.rejectProjectStatus = REJECT_PROJECT_SUCCESS
      let rejectIndex = nextState.incomingProjects.findIndex((e, index) => e.id === action.data.id)
      nextState.incomingProjects.splice(rejectIndex, 1)
      nextState.incomingProjects = [...nextState.incomingProjects]
      return nextState
    case REJECT_PROJECT_FAILED:
      nextState.rejectProjectStatus = REJECT_PROJECT_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState

    //REVIEW SHARED MOODBOARD
    case REVIEW_SHARED_MOODBOARD_REQUESTED:
      nextState.reviewSharedMoodboardStatus = REVIEW_SHARED_MOODBOARD_REQUESTED
      return nextState
    case REVIEW_SHARED_MOODBOARD_SUCCESS:
      nextState.reviewSharedMoodboardStatus = REVIEW_SHARED_MOODBOARD_SUCCESS
      nextState.selectedProject = action.data
      let changedIndex = nextState.projects.findIndex((e, index) => e._id === action.data.id)
      nextState.projects[changedIndex].customer_feedback = "PENDING"
      nextState.projects = [...nextState.projects]
      return nextState
    case REVIEW_SHARED_MOODBOARD_FAILED:
      nextState.reviewSharedMoodboardStatus = REVIEW_SHARED_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState
  }

  return nextState;
}

export default projectReducer