export default {
    // PRODUCT LIST 
    products: [],
    totalPages: '',
    totalResults: '',
    listStatus: null,
    request3DModalStatus: null,
    designerProducts: [],
    designerListStatus: null,
    designerTotalPages: '',
    designerTotalResults: '',
    productPrice: '',
    getProductDetailsStatus: null,
    productDetails: ''
}