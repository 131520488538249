import { showErrorToast, showSuccessToast } from '../../../utility/showToast';
import {
  DESIGNER_PRODUCT_LIST_FAILED,
  DESIGNER_PRODUCT_LIST_REQUESTED,
  DESIGNER_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_REQUESTED,
  GET_PRODUCT_SUCCESS,
  PRODUCT_3D_MODAL_FAILED,
  PRODUCT_3D_MODAL_REQUESTED,
  PRODUCT_3D_MODAL_SUCCESS,
  PRODUCT_LIST_BY_ALL_FAILED,
  PRODUCT_LIST_BY_ALL_REQUESTED,
  PRODUCT_LIST_BY_ALL_SUCCESS,
  PRODUCT_LIST_FAILED,
  PRODUCT_LIST_REQUESTED,
  PRODUCT_LIST_SUCCESS,
} from '../../statusTypes';
import initialState from './initialState';

const productReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state });

  switch (action.type) {
    //PRODUCT LIST
    case PRODUCT_LIST_REQUESTED:
      nextState.listStatus = PRODUCT_LIST_REQUESTED;
      return nextState;
    case PRODUCT_LIST_SUCCESS:
      nextState.listStatus = PRODUCT_LIST_SUCCESS;
      nextState.products = action.data;
      return nextState;
    case PRODUCT_LIST_FAILED:
      nextState.listStatus = PRODUCT_LIST_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //PRODUCT LIST BY ALL
    case PRODUCT_LIST_BY_ALL_REQUESTED:
      nextState.listStatus = PRODUCT_LIST_BY_ALL_REQUESTED;
      return nextState;
    case PRODUCT_LIST_BY_ALL_SUCCESS:
      nextState.listStatus = PRODUCT_LIST_BY_ALL_SUCCESS;

      if (action.data.product.results.length) {
        nextState.products = [...action.data.product.results];
        nextState.productPrice = [...action.data.productPrice];
      } else {
        nextState.products = action.data.product.results;
        nextState.productPrice = action.data.productPrice;
      }
      nextState.totalPages = action.data.product.totalPages;
      nextState.totalResults = action.data.product.totalResults;
      return nextState;
    case PRODUCT_LIST_BY_ALL_FAILED:
      nextState.listStatus = PRODUCT_LIST_BY_ALL_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //PRODUCT LIST BY ALL
    case DESIGNER_PRODUCT_LIST_REQUESTED:
      nextState.designerListStatus = DESIGNER_PRODUCT_LIST_REQUESTED;
      return nextState;
    case DESIGNER_PRODUCT_LIST_SUCCESS:
      nextState.designerListStatus = DESIGNER_PRODUCT_LIST_SUCCESS;
      if (
        action.data.totalPages === nextState.designerTotalPages &&
        action.data.page > 1
      ) {
        nextState.designerProducts = [
          ...nextState.designerProducts,
          ...action.data.results,
        ];
      } else {
        nextState.designerProducts = action.data.results;
      }
      nextState.designerTotalPages = action.data.totalPages;
      nextState.designerTotalResults = action.data.totalResults;
      return nextState;
    case DESIGNER_PRODUCT_LIST_FAILED:
      nextState.designerListStatus = DESIGNER_PRODUCT_LIST_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //REQUEST 3D MODEL
    case PRODUCT_3D_MODAL_REQUESTED:
      nextState.request3DModalStatus = PRODUCT_3D_MODAL_REQUESTED;
      return nextState;
    case PRODUCT_3D_MODAL_SUCCESS:
      showSuccessToast({
        title: 'Success',
        description: `Successfully requested for 3D model`,
      });
      nextState.request3DModalStatus = PRODUCT_3D_MODAL_SUCCESS;
      return nextState;
    case PRODUCT_3D_MODAL_FAILED:
      nextState.request3DModalStatus = PRODUCT_3D_MODAL_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //GET PRODUCT
    case GET_PRODUCT_REQUESTED:
      nextState.getProductDetailsStatus = GET_PRODUCT_REQUESTED;
      return nextState;
    case GET_PRODUCT_SUCCESS:
      nextState.getProductDetailsStatus = GET_PRODUCT_SUCCESS;
      nextState.productDetails = action.data;
      return nextState;
    case GET_PRODUCT_FAILED:
      nextState.getProductDetailsStatus = GET_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;
  }
  return nextState;
};
export default productReducer;
