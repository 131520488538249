import initialState from "./initialState";
import { showErrorToast, showSuccessToast } from "../../../utility/showToast"
import {
  CREATE_THREE_D_MOODBOARD_FAILED,
  CREATE_THREE_D_MOODBOARD_REQUESTED,
  CREATE_THREE_D_MOODBOARD_SUCCESS,
  DELETE_THREE_D_MOODBOARD_FAILED,
  DELETE_THREE_D_MOODBOARD_REQUESTED,
  DELETE_THREE_D_MOODBOARD_SUCCESS,
  DUPLICATE_THREE_D_MOODBOARD_FAILED,
  DUPLICATE_THREE_D_MOODBOARD_REQUESTED,
  DUPLICATE_THREE_D_MOODBOARD_SUCCESS,
  GET_THREE_D_MOODBOARD_FAILED,
  GET_THREE_D_MOODBOARD_REQUESTED,
  GET_THREE_D_MOODBOARD_SUCCESS,
  LIST_THREE_D_MOODBOARD_FAILED,
  LIST_THREE_D_MOODBOARD_REQUESTED,
  LIST_THREE_D_MOODBOARD_SUCCESS,
  SHARE_THREE_D_MOODBOARD_FAILED,
  SHARE_THREE_D_MOODBOARD_REQUESTED,
  SHARE_THREE_D_MOODBOARD_SUCCESS
} from "../../statusTypes";

const threeDMoodboardReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state })
  switch (action.type) {
    //CREATE 3D MOODBOARD 
    case CREATE_THREE_D_MOODBOARD_REQUESTED:
      nextState.moodboard.createMoodboardStatus = CREATE_THREE_D_MOODBOARD_REQUESTED
      return nextState;
    case CREATE_THREE_D_MOODBOARD_SUCCESS:
      nextState.moodboard.createMoodboardStatus = CREATE_THREE_D_MOODBOARD_SUCCESS
      //nextState.moodboard.moodboardList = [action.data, ...nextState.moodboard.moodboardList]

      return nextState;
    case CREATE_THREE_D_MOODBOARD_FAILED:
      nextState.moodboard.createMoodboardStatus = CREATE_THREE_D_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //3D MOODBOARD LIST
    case LIST_THREE_D_MOODBOARD_REQUESTED:
      nextState.moodboard.listStatus = LIST_THREE_D_MOODBOARD_REQUESTED
      return nextState;
    case LIST_THREE_D_MOODBOARD_SUCCESS:
      nextState.moodboard.listStatus = LIST_THREE_D_MOODBOARD_SUCCESS
      nextState.moodboard.moodboardList = action.data
      return nextState;
    case LIST_THREE_D_MOODBOARD_FAILED:
      nextState.moodboard.listStatus = LIST_THREE_D_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //GET 3D MOODBOARD 
    case GET_THREE_D_MOODBOARD_REQUESTED:
      nextState.moodboard.get3DMoodboardStatus = GET_THREE_D_MOODBOARD_REQUESTED
      return nextState;
    case GET_THREE_D_MOODBOARD_SUCCESS:
      nextState.moodboard.get3DMoodboardStatus = GET_THREE_D_MOODBOARD_SUCCESS
      nextState.moodboard.threeDMoodboard = action.data
      return nextState;
    case GET_THREE_D_MOODBOARD_FAILED:
      nextState.moodboard.get3DMoodboardStatus = GET_THREE_D_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //SHARE 3D MOODBOARD 
    case SHARE_THREE_D_MOODBOARD_REQUESTED:
      nextState.moodboard.publishSpaceStatus = SHARE_THREE_D_MOODBOARD_REQUESTED
      return nextState;
    case SHARE_THREE_D_MOODBOARD_SUCCESS:
      showSuccessToast({
        title: 'Success',
        description: 'Successfully shared to client '
      })
      nextState.moodboard.publishSpaceStatus = SHARE_THREE_D_MOODBOARD_SUCCESS
      return nextState;
    case SHARE_THREE_D_MOODBOARD_FAILED:
      nextState.moodboard.publishSpaceStatus = SHARE_THREE_D_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //DUPLICATE 3D MOODBOARD 
    case DUPLICATE_THREE_D_MOODBOARD_REQUESTED:
      nextState.moodboard.duplicateMoodboardStatus = DUPLICATE_THREE_D_MOODBOARD_REQUESTED
      return nextState;
    case DUPLICATE_THREE_D_MOODBOARD_SUCCESS:
      nextState.moodboard.duplicateMoodboardStatus = DUPLICATE_THREE_D_MOODBOARD_SUCCESS
      nextState.moodboard.moodboardList = [action.data, ...nextState.moodboard.moodboardList]
      return nextState;
    case DUPLICATE_THREE_D_MOODBOARD_FAILED:
      nextState.moodboard.duplicateMoodboardStatus = DUPLICATE_THREE_D_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //DELETE MOODBOARD 
    case DELETE_THREE_D_MOODBOARD_REQUESTED:
      nextState.moodboard.deleteMoodboardStatus = DELETE_THREE_D_MOODBOARD_REQUESTED
      return nextState;
    case DELETE_THREE_D_MOODBOARD_SUCCESS:
      nextState.moodboard.deleteMoodboardStatus = DELETE_THREE_D_MOODBOARD_SUCCESS
      const removeItem = nextState.moodboard.moodboardList.findIndex((e, index) => e.id === action.data.id)
      nextState.moodboard.moodboardList.splice(removeItem, 1)
      showSuccessToast({
        title: 'Success',
        description: 'Deleted Successfully'
      })
      return nextState;
    case DELETE_THREE_D_MOODBOARD_FAILED:
      nextState.moodboard.deleteMoodboardStatus = DELETE_THREE_D_MOODBOARD_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    default:
      return nextState
  }
}

export default threeDMoodboardReducer