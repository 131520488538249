import initialState from "./initialState";
import { showErrorToast, showSuccessToast } from "../../../utility/showToast"
import {
  GET_DESIGNER_DATA_FAILED,
  GET_DESIGNER_DATA_REQUESTED,
  GET_DESIGNER_DATA_SUCCESS,
  GET_DESIGNER_STATS_FAILED,
  GET_DESIGNER_STATS_REQUESTED,
  GET_DESIGNER_STATS_SUCCESS,
  REQUEST_DESIGNER_EDIT_FAILED,
  REQUEST_DESIGNER_EDIT_REQUESTED,
  REQUEST_DESIGNER_EDIT_SUCCESS,
  SAVE_DESIGNER_AVATAR_FAILED,
  SAVE_DESIGNER_AVATAR_REQUESTED,
  SAVE_DESIGNER_AVATAR_SUCCESS,
  SAVE_DESIGNER_SIGNATURE_FAILED,
  SAVE_DESIGNER_SIGNATURE_REQUESTED,
  SAVE_DESIGNER_SIGNATURE_SUCCESS
} from "../../statusTypes";

const designerReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state })

  switch (action.type) {
    //SAVE AVATAR
    case SAVE_DESIGNER_AVATAR_REQUESTED:
      nextState.saveAvatarStatus = SAVE_DESIGNER_AVATAR_REQUESTED
      return nextState;
    case SAVE_DESIGNER_AVATAR_SUCCESS:
      nextState.saveAvatarStatus = SAVE_DESIGNER_AVATAR_SUCCESS
      showSuccessToast({
        title: 'Success',
        description: 'Profile Picture Successfully Updated'
      })
      return nextState;
    case SAVE_DESIGNER_AVATAR_FAILED:
      nextState.saveAvatarStatus = SAVE_DESIGNER_AVATAR_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //SAVE SIGNATURE
    case SAVE_DESIGNER_SIGNATURE_REQUESTED:
      nextState.saveSignatureStatus = SAVE_DESIGNER_SIGNATURE_REQUESTED
      return nextState;
    case SAVE_DESIGNER_SIGNATURE_SUCCESS:
      nextState.saveSignatureStatus = SAVE_DESIGNER_SIGNATURE_SUCCESS
      showSuccessToast({
        title: 'Success',
        description: 'Signature Successfully Updated'
      })
      return nextState;
    case SAVE_DESIGNER_SIGNATURE_FAILED:
      nextState.saveSignatureStatus = SAVE_DESIGNER_SIGNATURE_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //REQUEST EDIT CHANGES
    case REQUEST_DESIGNER_EDIT_REQUESTED:
      nextState.editChangesRequestedStatus = REQUEST_DESIGNER_EDIT_REQUESTED
      return nextState;
    case REQUEST_DESIGNER_EDIT_SUCCESS:
      nextState.editChangesRequestedStatus = REQUEST_DESIGNER_EDIT_SUCCESS
      return nextState;
    case REQUEST_DESIGNER_EDIT_FAILED:
      nextState.editChangesRequestedStatus = REQUEST_DESIGNER_EDIT_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //GET DESIGNER DATA
    case GET_DESIGNER_DATA_REQUESTED:
      nextState.designerDataStatus = GET_DESIGNER_DATA_REQUESTED
      return nextState;
    case GET_DESIGNER_DATA_SUCCESS:
      nextState.designerDataStatus = GET_DESIGNER_DATA_SUCCESS
      return nextState;
    case GET_DESIGNER_DATA_FAILED:
      nextState.designerDataStatus = GET_DESIGNER_DATA_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;

    //GET DESIGNER STATS
    case GET_DESIGNER_STATS_REQUESTED:
      nextState.designerStatsStatus = GET_DESIGNER_STATS_REQUESTED
      return nextState;
    case GET_DESIGNER_STATS_SUCCESS:
      nextState.designerStatsStatus = GET_DESIGNER_STATS_SUCCESS
      nextState.designerStats = action.data
      return nextState;
    case GET_DESIGNER_STATS_FAILED:
      nextState.designerStatsStatus = GET_DESIGNER_STATS_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;
    default:
      return nextState;
  }
}

export default designerReducer