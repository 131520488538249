import { createStandaloneToast } from "@chakra-ui/toast"

const toast = createStandaloneToast()

/**
 * 
 * @param {import("@chakra-ui/toast").UseToastOptions} options 
 */
export const showToast = (options) => {
  let { description } = options
  if (typeof description !== 'string') description = description.toString()
  
  toast({
    ...options,
    description
  })
}

export const showErrorToast = ({ title, description, position = 'top-right' }) => {
  showToast({
    title,
    description,
    status: 'error',
    position,
    isClosable: true,
    duration: 5000
  })
}

export const showSuccessToast = ({ title, description, position = 'top-right' }) => {
  showToast({
    title,
    description,
    status: 'success',
    position,
    isClosable: true,
    duration: 3000
  })
}