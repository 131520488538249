import initialState from "./initialState";
import { showErrorToast } from "../../../utility/showToast"
import {
  GET_BRANDS_FAILED,
  GET_BRANDS_REQUESTED,
  GET_BRANDS_SUCCESS
} from "../../statusTypes";

const vendorReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state })

  switch (action.type) {
    //BRANDS LIST
    case GET_BRANDS_REQUESTED:
      nextState.brandListStatus = GET_BRANDS_REQUESTED
      return nextState;
    case GET_BRANDS_SUCCESS:
      nextState.brandListStatus = GET_BRANDS_SUCCESS
      nextState.brands = [...action.data]
      return nextState;
    case GET_BRANDS_FAILED:
      nextState.brandListStatus = GET_BRANDS_FAILED
      showErrorToast({
        title: 'Error',
        description: action.error.message
      })
      return nextState;
    default:
      return nextState;
  }
}

export default vendorReducer