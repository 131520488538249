import { kExpandedSidebarWidth, kSidebarShrinkWidth } from "@src/@core/config/LayoutConstraints";
import { EXPAND_SIDEBAR, SHRINK_SIDEBAR } from '@src/redux/statusTypes'
import initialState from "./initialState";

const layoutReducer = (state = initialState, action) => {
  if (state.mode === action.type) return state
  const nextState = Object.assign({}, state)

  switch (action.type) {
    case EXPAND_SIDEBAR:
      nextState.sidebarWidth = kExpandedSidebarWidth
      nextState.mode = EXPAND_SIDEBAR
      return nextState
    case SHRINK_SIDEBAR:
      nextState.sidebarWidth = kSidebarShrinkWidth
      nextState.mode = SHRINK_SIDEBAR
      return nextState
    default:
      return nextState
  }
}

export default layoutReducer