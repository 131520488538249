import {
  ADD_ALL_TO_STUDIO_FAILED,
  ADD_ALL_TO_STUDIO_REQUESTED,
  ADD_ALL_TO_STUDIO_SUCCESS,
  ADD_TO_STUDIO_FAILED,
  ADD_TO_STUDIO_REQUESTED,
  ADD_TO_STUDIO_SUCCESS,
  BRANDS,
  CATEGORY,
  COLORS,
  DIMENSIONS,
  MATERIAL,
  MX_SEIVE_FILTER_ACTION,
  NAME,
  PATTERN,
  PRICE,
  PRODUCT_FILTER_ACTION,
  REMOVE_ALL_MX_SEIVE_FILTERS,
  REMOVE_ALL_PRODUCT_CATALOGUE_FILTERS,
  REMOVE_MX_SEIVE_FILTER_ACTION,
  REMOVE_PRODUCT_FILTER_ACTION,
  ROOMS,
  STYLE,
  SET_PRODUCT_CATALOGUE_PAGE_NO,
} from '../../statusTypes';
import { showErrorToast } from '../../../utility/showToast';
import initialState from './initialState';

const productCatalogueReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state });
  switch (action.type) {
    //PRODUCT CATALOGUE TYPE
    case PRODUCT_FILTER_ACTION:
      nextState.productCataloguePageNo.pageNo = 1;
      switch (action.data.type) {
        case CATEGORY:
          if (
            nextState.productCatalogueFilters.filters.category.length <
            action.data.data.length
          ) {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count + 1;
          } else {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count - 1;
          }
          nextState.productCatalogueFilters.filters.category = [
            ...action.data.data,
          ];
          return nextState;
        case BRANDS:
          if (
            nextState.productCatalogueFilters.filters.brands.length <
            action.data.data.length
          ) {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count + 1;
          } else {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count - 1;
          }
          nextState.productCatalogueFilters.filters.brands = [
            ...action.data.data,
          ];
          return nextState;
        case STYLE:
          nextState.productCatalogueFilters.filters.style = [
            ...action.data.data,
          ];
          nextState.productCatalogueFilters.count =
            nextState.productCatalogueFilters.count + 1;
          return nextState;
        case MATERIAL:
          nextState.productCatalogueFilters.filters.material = [
            ...action.data.data,
          ];
          nextState.productCatalogueFilters.count =
            nextState.productCatalogueFilters.count + 1;
          return nextState;
        case DIMENSIONS:
          nextState.productCatalogueFilters.filters.dimensions =
            action.data.data;
          nextState.productCatalogueFilters.count =
            nextState.productCatalogueFilters.count + 1;
          return nextState;
        case PATTERN:
          nextState.productCatalogueFilters.filters.pattern = [
            ...action.data.data,
          ];
          nextState.productCatalogueFilters.count =
            nextState.productCatalogueFilters.count + 1;
          return nextState;
        case COLORS:
          nextState.productCatalogueFilters.filters.color = action.data.data;
          nextState.productCatalogueFilters.count =
            nextState.productCatalogueFilters.count + 1;
          return nextState;
        case PRICE:
          if (
            nextState.productCatalogueFilters.filters.price.from === '' &&
            nextState.productCatalogueFilters.filters.price.to === '' &&
            (action.data.data[0] || action.data.data[1])
          ) {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count + 1;
          }
          nextState.productCatalogueFilters.filters.price.from =
            action.data.data[0];
          nextState.productCatalogueFilters.filters.price.to =
            action.data.data[1];
          return nextState;
        case NAME:
          if (
            !nextState.productCatalogueFilters.filters.name &&
            action.data.data
          ) {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count + 1;
          } else if (!action.data.data) {
            nextState.productCatalogueFilters.count =
              nextState.productCatalogueFilters.count - 1;
          }
          nextState.productCatalogueFilters.filters.name = action.data.data;
          return nextState;
        case ROOMS:
          nextState.productCatalogueFilters.filters.rooms = [action.data.data];
          nextState.productCatalogueFilters.count =
            nextState.productCatalogueFilters.count + 1;
        default:
          return nextState;
      }

    case REMOVE_PRODUCT_FILTER_ACTION:
      nextState.productCataloguePageNo.pageNo = 1;
      nextState.productCatalogueFilters.filters[action.data.type] =
        action.data.data;
      nextState.productCatalogueFilters.count =
        nextState.productCatalogueFilters.count - 1;
      return nextState;
    case REMOVE_ALL_PRODUCT_CATALOGUE_FILTERS:
      nextState.productCataloguePageNo.pageNo = 1;
      nextState.productCatalogueFilters.filters.rooms = [];
      nextState.productCatalogueFilters.filters.brands = [];
      nextState.productCatalogueFilters.filters.price = { from: '', to: '' };
      nextState.productCatalogueFilters.filters.name = '';
      nextState.productCatalogueFilters.filters.style = [];
      nextState.productCatalogueFilters.filters.material = [];
      nextState.productCatalogueFilters.filters.pattern = [];
      nextState.productCatalogueFilters.filters.color = [];
      nextState.productCatalogueFilters.filters.dimensions = '';
      nextState.productCatalogueFilters.filters.category = [];
      nextState.productCatalogueFilters.count = 0;
      return nextState;

    //MX-SEIVE TYPE
    case MX_SEIVE_FILTER_ACTION:
      nextState.productCataloguePageNo.pageNo = 1;
      switch (action.data.type) {
        case CATEGORY:
          nextState.mxSeiveFilters.filters.category = [...action.data.data];
          return nextState;
        case BRANDS:
          nextState.mxSeiveFilters.filters.brands = [...action.data.data];
          return nextState;
        case STYLE:
          nextState.mxSeiveFilters.filters.style = [...action.data.data];
          return nextState;
        case MATERIAL:
          nextState.mxSeiveFilters.filters.material = [...action.data.data];
          return nextState;
        case DIMENSIONS:
          nextState.mxSeiveFilters.filters.dimensions = action.data.data;
          return nextState;
        case PATTERN:
          nextState.mxSeiveFilters.filters.pattern = [...action.data.data];
          return nextState;
        case COLORS:
          nextState.mxSeiveFilters.filters.color = [...action.data.data];
          return nextState;
        case PRICE:
          nextState.mxSeiveFilters.filters.price.from = action.data.data[0];
          nextState.mxSeiveFilters.filters.price.to = action.data.data[1];
          return nextState;
        case NAME:
          nextState.mxSeiveFilters.filters.name = action.data.data;
          return nextState;
        case ROOMS:
          nextState.mxSeiveFilters.filters.rooms = [action.data.data];
          return nextState;
        default:
          return nextState;
      }

    case REMOVE_MX_SEIVE_FILTER_ACTION:
      nextState.productCataloguePageNo.pageNo = 1;
      nextState.mxSeiveFilters.filters[action.data.type] = action.data.data;
      return nextState;
    case REMOVE_ALL_MX_SEIVE_FILTERS:
      nextState.productCataloguePageNo.pageNo = 1;
      nextState.mxSeiveFilters.filters.rooms = [];
      nextState.mxSeiveFilters.filters.brands = [];
      nextState.mxSeiveFilters.filters.price = { from: '', to: '' };
      nextState.mxSeiveFilters.filters.name = '';
      nextState.mxSeiveFilters.filters.style = [];
      nextState.mxSeiveFilters.filters.material = [];
      nextState.mxSeiveFilters.filters.pattern = [];
      nextState.mxSeiveFilters.filters.color = [];
      nextState.mxSeiveFilters.filters.dimensions = '';
      nextState.mxSeiveFilters.filters.category = [];
      nextState.mxSeiveFilters.count = 0;
      return nextState;

    //ADD TO STUDIO
    case ADD_TO_STUDIO_REQUESTED:
      nextState.status.addToStudioStatus = ADD_TO_STUDIO_REQUESTED;
      return nextState;
    case ADD_TO_STUDIO_SUCCESS:
      nextState.status.addToStudioStatus = ADD_TO_STUDIO_SUCCESS;
      return nextState;
    case ADD_TO_STUDIO_FAILED:
      nextState.status.addToStudioStatus = ADD_TO_STUDIO_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //ADD ALL TO STUDIO
    case ADD_ALL_TO_STUDIO_REQUESTED:
      nextState.status.addAllToStudioStatus = ADD_ALL_TO_STUDIO_REQUESTED;
      return nextState;
    case ADD_ALL_TO_STUDIO_SUCCESS:
      nextState.status.addAllToStudioStatus = ADD_ALL_TO_STUDIO_SUCCESS;
      return nextState;
    case ADD_ALL_TO_STUDIO_FAILED:
      nextState.status.addAllToStudioStatus = ADD_ALL_TO_STUDIO_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;
    case SET_PRODUCT_CATALOGUE_PAGE_NO:
      nextState.productCataloguePageNo.pageNo = action.data.data;
      return nextState;

    default:
      return nextState;
  }
};
export default productCatalogueReducer;
