import initialState from "./initialState";
import { showErrorToast } from "../../../utility/showToast"
import {
    ADD_NOTES_FAILED,
    ADD_NOTES_REQUESTED,
    ADD_NOTES_SUCCESS,
    DELETE_NOTES_FAILED,
    DELETE_NOTES_REQUESTED,
    DELETE_NOTES_SUCCESS,
    NOTES_LIST_FAILED,
    NOTES_LIST_REQUESTED,
    NOTES_LIST_SUCCESS,
    UPDATE_NOTES_FAILED,
    UPDATE_NOTES_REQUESTED,
    UPDATE_NOTES_SUCCESS
} from "../../statusTypes";


const noteReducer = (state = initialState, action) => {
    const nextState = Object.assign({}, { ...state })

    switch (action.type) {
        //ADD NOTES 
        case ADD_NOTES_REQUESTED:
            nextState.addStatus = ADD_NOTES_REQUESTED
            return nextState;
        case ADD_NOTES_SUCCESS:
            nextState.addStatus = ADD_NOTES_SUCCESS
            if (nextState.notes && nextState.notes.length) {
                nextState.notes = [action.data, ...nextState.notes]
            } else {
                nextState.notes = [action.data]
            }
            return nextState;
        case ADD_NOTES_FAILED:
            nextState.addStatus = ADD_NOTES_FAILED
            showErrorToast({
                title: 'Error',
                description: action.error.message
            })
            return nextState;

        //NOTES LIST
        case NOTES_LIST_REQUESTED:
            nextState.listStatus = NOTES_LIST_REQUESTED
            return nextState;
        case NOTES_LIST_SUCCESS:
            nextState.listStatus = NOTES_LIST_SUCCESS
            nextState.notes = action.data
            return nextState;
        case NOTES_LIST_FAILED:
            nextState.listStatus = NOTES_LIST_FAILED
            showErrorToast({
                title: 'Error',
                description: action.error.message
            })
            return nextState;

        //UPDATE NOTES
        case UPDATE_NOTES_REQUESTED:
            nextState.updateStatus = UPDATE_NOTES_REQUESTED
            return nextState;
        case UPDATE_NOTES_SUCCESS:
            nextState.updateStatus = UPDATE_NOTES_SUCCESS
            const updatedNote = nextState.notes.findIndex((e, index) => e.id === action.data.id)
            nextState.notes[updatedNote].description = action.data.description
            return nextState;
        case UPDATE_NOTES_FAILED:
            nextState.updateStatus = UPDATE_NOTES_FAILED
            showErrorToast({
                title: 'Error',
                description: action.error.message
            })
            return nextState;

        //DELETE NOTES
        case DELETE_NOTES_REQUESTED:
            nextState.deleteStatus = DELETE_NOTES_REQUESTED
            return nextState;
        case DELETE_NOTES_SUCCESS:
            nextState.deleteStatus = DELETE_NOTES_SUCCESS
            const removeItem = nextState.notes.findIndex((e, index) => e.id === action.data.id)
            nextState.notes.splice(removeItem, 1)
            return nextState;
        case DELETE_NOTES_FAILED:
            nextState.deleteStatus = DELETE_NOTES_FAILED
            showErrorToast({
                title: 'Error',
                description: action.error.message
            })
            return nextState;
        default:
            return nextState
    }
}

export default noteReducer