import { VIEW_INITIATED } from '@src/constants/ViewStatus';

export default {
  isAuthenticated: false,
  currentUser: null,
  status: VIEW_INITIATED,
  currentSessionValidated: false,
  login: {
    status: null,
    data: {},
    error: null
  },
  password: {
    status: null,
    resetStatus: null
  }
}