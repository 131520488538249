import { ColorModeScript, Spinner } from '@chakra-ui/react';
import React, { StrictMode, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/storeConfig/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';

SuperTokens.init({
  appInfo: {
    apiDomain: `${process.env.REACT_APP_MXD_BACKEND_BASE_URL}`,
    apiBasePath: '/v1/designer',
    appName: 'pixelandmortar',
    websiteBasePath: '/',
  },
  recipeList: [Session.init(), EmailPassword.init()],
});

// ** Lazy load app
const PMDesignerApp = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <StrictMode>
        <Suspense fallback={<Spinner />}>
          <ColorModeScript />
          <PMDesignerApp />
        </Suspense>
      </StrictMode>
    </PersistGate>
  </Provider>,

  document.getElementById('pm-designer-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
