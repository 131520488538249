// AUTH STATUS
export const AUTH_REQUESTED = 'AUTH_REQUESTED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';

// LOGIN STATES
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

// LAYOUT STATES
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR';
export const SHRINK_SIDEBAR = 'SHRINK_SIDEBAR';

//FORGOT STATES
export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD';

//RESET STATES
export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

//HOME TAB ACTION
export const HOME_TAB_ACTION = 'HOME_TAB_ACTION';
export const DASHBOARD_TAB = 'DASHBOARD_TAB';
export const PROJECT_TAB = 'PROJECT_TAB';
export const FINANCE_TAB = 'FINANCE_TAB';

//PRODUCT CATALOGUE ACTION
export const PRODUCT_FILTER_ACTION = 'PRODUCT_FILTER_ACTION';
export const REMOVE_PRODUCT_FILTER_ACTION = 'REMOVE_PRODUCT_FILTER_ACTION';
export const REMOVE_ALL_PRODUCT_CATALOGUE_FILTERS =
  'REMOVE_ALL_PRODUCT_CATALOGUE_FILTERS';
export const SET_PRODUCT_CATALOGUE_PAGE_NO = 'SET_PRODUCT_CATALOGUE_PAGE_NO';

//MX-SEIVE ACTION
export const MX_SEIVE_FILTER_ACTION = 'MX_SEIVE_FILTER_ACTION';
export const REMOVE_MX_SEIVE_FILTER_ACTION = 'REMOVE_MX_SEIVE_FILTER_ACTION';
export const REMOVE_ALL_MX_SEIVE_FILTERS = 'REMOVE_ALL_MX_SEIVE_FILTERS';

//PRODUCT CATALOGUE FILTER TYPE
export const STYLE_DETAILS = 'styleDetails';
export const BRANDS = 'brands';
export const STYLE = 'style';
export const MATERIAL = 'material';
export const PATTERN = 'pattern';
export const COLORS = 'color';
export const DIMENSIONS = 'dimensions';
export const PRICE = 'price';
export const NAME = 'name';
export const ROOMS = 'rooms';
export const CATEGORY = 'category';

//PROJECT TAB ACTION
export const PROJECT_DETAILS_TAB_ACTION = 'PROJECT_TAB_ACTION';
export const CUSTOMER_STYLE_TAB = 'CUSTOMER_STYLE_TAB';
export const CUSTOMER_NEEDS_TAB = 'CUSTOMER_NEEDS_TAB';
export const SPACE_DETAILS_TAB = 'SPACE_DETAILS_TAB';
export const MOODBOARD_REVIEW_TAB = 'MOODBOARD_REVIEW_TAB';

//SETTINGS TAB ACTION
export const SETTINGS_TAB_ACTION = 'SETTINGS_TAB_ACTION';
export const ABOUT_US_TAB = 'ABOUT_US_TAB';
export const TRAINING_MODULE_TAB = 'TRAINING_MODULE_TAB';
export const FAQ_TAB = 'FAQ_TAB';
export const HELP_AND_SUPPORT_TAB = 'HELP_AND_SUPPORT_TAB';
export const TERMS_AND_CONDITION_TAB = 'TERMS_AND_CONDITION_TAB';
export const PRIVACY_POLICY_TAB = 'PRIVACY_POLICY_TAB';

// PROJECT ACTIONS
export const INCOMING_PROJECT_LIST_REQUESTED =
  'INCOMING_PROJECT_LIST_REQUESTED';
export const INCOMING_PROJECT_LIST_SUCCESS = 'INCOMING_PROJECT_LIST_SUCCESS';
export const INCOMING_PROJECT_LIST_FAILED = 'INCOMING_PROJECT_LIST_FAILED';

export const UPDATE_PROJECT_STATUS_REQUESTED =
  'UPDATE_PROJECT_STATUS_REQUESTED';
export const UPDATE_PROJECT_STATUS_SUCCESS = 'UPDATE_PROJECT_STATUS_SUCCESS';
export const UPDATE_PROJECT_STATUS_FAILED = 'UPDATE_PROJECT_STATUS_FAILED';

export const PROJECT_LIST_REQUESTED = 'PROJECT_LIST_REQUESTED';
export const PROJECT_LIST_SUCCESS = 'PROJECT_LIST_SUCCESS';
export const PROJECT_LIST_FAILED = 'PROJECT_LIST_FAILED';

export const SINGLE_PROJECT_REQUESTED = 'SINGLE_PROJECT_REQUESTED';
export const SINGLE_PROJECT_SUCCESS = 'SINGLE_PROJECT_SUCCESS';
export const SINGLE_PROJECT_FAILED = 'SINGLE_PROJECT_FAILED';

export const ACCEPT_PROJECT_REQUESTED = 'ACCEPT_PROJECT_REQUESTED';
export const ACCEPT_PROJECT_SUCCESS = 'ACCEPT_PROJECT_SUCCESS';
export const ACCEPT_PROJECT_FAILED = 'ACCEPT_PROJECT_FAILED';

export const REJECT_PROJECT_REQUESTED = 'REJECT_PROJECT_REQUESTED';
export const REJECT_PROJECT_SUCCESS = 'REJECT_PROJECT_SUCCESS';
export const REJECT_PROJECT_FAILED = 'REJECT_PROJECT_FAILED';

export const REVIEW_SHARED_MOODBOARD_REQUESTED =
  'REVIEW_SHARED_MOODBOARD_REQUESTED';
export const REVIEW_SHARED_MOODBOARD_SUCCESS =
  'REVIEW_SHARED_MOODBOARD_SUCCESS';
export const REVIEW_SHARED_MOODBOARD_FAILED = 'REVIEW_SHARED_MOODBOARD_FAILED';

export const UPDATE_MOODBOARD_SHARED = 'UPDATE_MOODBOARD_SHARED';

//PRODUCT ACTIONS
export const PRODUCT_LIST_REQUESTED = 'PRODUCT_LIST_REQUESTED';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILED = 'PRODUCT_LIST_FAILED';

export const DESIGNER_PRODUCT_LIST_REQUESTED =
  'DESIGNER_PRODUCT_LIST_REQUESTED';
export const DESIGNER_PRODUCT_LIST_SUCCESS = 'DESIGNER_PRODUCT_LIST_SUCCESS';
export const DESIGNER_PRODUCT_LIST_FAILED = 'DESIGNER_PRODUCT_LIST_FAILED';

export const PRODUCT_LIST_BY_ALL_REQUESTED = 'PRODUCT_LIST_BY_ALL_REQUESTED';
export const PRODUCT_LIST_BY_ALL_SUCCESS = 'PRODUCT_LIST_BY_ALL_SUCCESS';
export const PRODUCT_LIST_BY_ALL_FAILED = 'PRODUCT_LIST_BY_ALL_FAILED';

export const PRODUCT_3D_MODAL_REQUESTED = 'PRODUCT_3D_MODAL_REQUESTED';
export const PRODUCT_3D_MODAL_SUCCESS = 'PRODUCT_3D_MODAL_SUCCESS';
export const PRODUCT_3D_MODAL_FAILED = 'PRODUCT_3D_MODAL_FAILED';

export const GET_PRODUCT_REQUESTED = 'GET_PRODUCT_REQUESTED';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

//NOTES
export const ADD_NOTES_REQUESTED = 'ADD_NOTES_REQUESTED';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAILED = 'ADD_NOTES_FAILED';

export const NOTES_LIST_REQUESTED = 'NOTES_LIST_REQUESTED';
export const NOTES_LIST_SUCCESS = 'NOTES_LIST_SUCCESS';
export const NOTES_LIST_FAILED = 'NOTES_LIST_FAILED';

export const UPDATE_NOTES_REQUESTED = 'UPDATE_NOTES_REQUESTED';
export const UPDATE_NOTES_SUCCESS = 'UPDATE_NOTES_SUCCESS';
export const UPDATE_NOTES_FAILED = 'UPDATE_NOTES_FAILED';

export const DELETE_NOTES_REQUESTED = 'DELETE_NOTES_REQUESTED';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_FAILED = 'DELETE_NOTES_FAILED';
//PRODUCT CATALOGUE
export const ADD_TO_STUDIO_REQUESTED = 'ADD_TO_STUDIO_REQUESTED';
export const ADD_TO_STUDIO_SUCCESS = 'ADD_TO_STUDIO_SUCCESS';
export const ADD_TO_STUDIO_FAILED = 'ADD_TO_STUDIO_FAILED';

//MX-SEIVE
export const ADD_ALL_TO_STUDIO_REQUESTED = 'ADD_ALL_TO_STUDIO_REQUESTED';
export const ADD_ALL_TO_STUDIO_SUCCESS = 'ADD_ALL_TO_STUDIO_SUCCESS';
export const ADD_ALL_TO_STUDIO_FAILED = 'ADD_ALL_TO_STUDIO_FAILED';

//2D MOODBOARD ACTIONS
export const MOODBOARD_TEMPLATE_LIST_REQUESTED =
  'MOODBOARD_TEMPLATE_LIST_REQUESTED';
export const MOODBOARD_TEMPLATE_LIST_SUCCESS =
  'MOODBOARD_TEMPLATE_LIST_SUCCESS';
export const MOODBOARD_TEMPLATE_LIST_FAILED = 'MOODBOARD_TEMPLATE_LIST_FAILED';

export const MOODBOARD_LIST_REQUESTED = 'MOODBOARD_LIST_REQUESTED';
export const MOODBOARD_LIST_SUCCESS = 'MOODBOARD_LIST_SUCCESS';
export const MOODBOARD_LIST_FAILED = 'MOODBOARD_LIST_FAILED';
export const MOODBOARD_RATING_SUCCESS = 'MOODBOARD_RATING_SUCCESS';
export const MOODBOARD_RATING_REQUESTED = 'MOODBOARD_RATING_REQUESTED';
export const MOODBOARD_RATING_FAILED = 'MOODBOARD_RATING_FAILED';

export const CREATE_MOODBOARD_REQUESTED = 'CREATE_MOODBOARD_REQUESTED';
export const CREATE_MOODBOARD_SUCCESS = 'CREATE_MOODBOARD_SUCCESS';
export const CREATE_MOODBOARD_FAILED = 'CREATE_MOODBOARD_FAILED';

export const DELETE_MOODBOARD_REQUESTED = 'DELETE_MOODBOARD_REQUESTED';
export const DELETE_MOODBOARD_SUCCESS = 'DELETE_MOODBOARD_SUCCESS';
export const DELETE_MOODBOARD_FAILED = 'DELETE_MOODBOARD_FAILED';

export const STUDIO_LIST_REQUESTED = 'STUDIO_LIST_REQUESTED';
export const STUDIO_LIST_SUCCESS = 'STUDIO_LIST_SUCCESS';
export const STUDIO_LIST_FAILED = 'STUDIO_LIST_FAILED';

export const UPDATE_MOODBOARD_REQUESTED = 'UPDATE_MOODBOARD_REQUESTED';
export const UPDATE_MOODBOARD_SUCCESS = 'UPDATE_MOODBOARD_SUCCESS';
export const UPDATE_MOODBOARD_FAILED = 'UPDATE_MOODBOARD_FAILED';
export const UPDATE_MOODBOARD_LIST = 'UPDATE_MOODBOARD_LIST';

export const ADD_MOODBOARD_PRODUCT_REQUESTED =
  'ADD_MOODBOARD_PRODUCT_REQUESTED';
export const ADD_MOODBOARD_PRODUCT_SUCCESS = 'ADD_MOODBOARD_PRODUCT_SUCCESS';
export const ADD_MOODBOARD_PRODUCT_FAILED = 'ADD_MOODBOARD_PRODUCT_FAILED';

export const DELETE_MOODBOARD_PRODUCT_REQUESTED =
  'DELETE_MOODBOARD_PRODUCT_REQUESTED';
export const DELETE_MOODBOARD_PRODUCT_SUCCESS =
  'DELETE_MOODBOARD_PRODUCT_SUCCESS';
export const DELETE_MOODBOARD_PRODUCT_FAILED =
  'DELETE_MOODBOARD_PRODUCT_FAILED';

export const GET_MOODBOARD_REQUESTED = 'GET_MOODBOARD_REQUESTED';
export const GET_MOODBOARD_SUCCESS = 'GET_MOODBOARD_SUCCESS';
export const GET_MOODBOARD_FAILED = 'GET_MOODBOARD_FAILED';

export const DUPLICATE_MOODBOARD_REQUESTED = 'DUPLICATE_MOODBOARD_REQUESTED';
export const DUPLICATE_MOODBOARD_SUCCESS = 'DUPLICATE_MOODBOARD_SUCCESS';
export const DUPLICATE_MOODBOARD_FAILED = 'DUPLICATE_MOODBOARD_FAILED';

export const UPDATE_PREVIEW_URL_REQUESTED = 'UPDATE_PREVIEW_URL_REQUESTED';
export const UPDATE_PREVIEW_URL_SUCCESS = 'UPDATE_PREVIEW_URL_SUCCESS';
export const UPDATE_PREVIEW_URL_FAILED = 'UPDATE_PREVIEW_URL_FAILED';

export const SHARE_TO_CLIENT_REQUESTED = 'SHARE_TO_CLIENT_REQUESTED';
export const SHARE_TO_CLIENT_SUCCESS = 'SHARE_TO_CLIENT_SUCCESS';
export const SHARE_TO_CLIENT_FAILED = 'SHARE_TO_CLIENT_FAILED';

export const SHARE_MOODBOARD_REQUESTED = 'SHARE_MOODBOARD_REQUESTED';
export const SHARE_MOODBOARD_SUCCESS = 'SHARE_MOODBOARD_SUCCESS';
export const SHARE_MOODBOARD_FAILED = 'SHARE_MOODBOARD_FAILED';

export const PUBLISH_TO_CLIENT_REQUESTED = 'PUBLISH_TO_CLIENT_REQUESTED';
export const PUBLISH_TO_CLIENT_SUCCESS = 'PUBLISH_TO_CLIENT_SUCCESS';
export const PUBLISH_TO_CLIENT_FAILED = 'PUBLISH_TO_CLIENT_FAILED';

export const ADD_ALTERNATE_PRODUCT_REQUESTED =
  'ADD_ALTERNATE_PRODUCT_REQUESTED';
export const ADD_ALTERNATE_PRODUCT_SUCCESS = 'ADD_ALTERNATE_PRODUCT_SUCCESS';
export const ADD_ALTERNATE_PRODUCT_FAILED = 'ADD_ALTERNATE_PRODUCT_FAILED';

export const SWAP_ALTERNATE_PRODUCT_REQUESTED =
  'SWAP_ALTERNATE_PRODUCT_REQUESTED';
export const SWAP_ALTERNATE_PRODUCT_SUCCESS = 'SWAP_ALTERNATE_PRODUCT_SUCCESS';
export const SWAP_ALTERNATE_PRODUCT_FAILED = 'SWAP_ALTERNATE_PRODUCT_FAILED';

export const PRODUCT_INTERACTIVITY_REQUESTED =
  'PRODUCT_INTERACTIVITY_REQUESTED';
export const PRODUCT_INTERACTIVITY_SUCCESS = 'PRODUCT_INTERACTIVITY_SUCCESS';
export const PRODUCT_INTERACTIVITY_FAILED = 'PRODUCT_INTERACTIVITY_FAILED';

export const HIDE_PRODUCT_REQUESTED = 'HIDE_PRODUCT_REQUESTED';
export const HIDE_PRODUCT_SUCCESS = 'HIDE_PRODUCT_SUCCESS';
export const HIDE_PRODUCT_FAILED = 'HIDE_PRODUCT_FAILED';

export const SHOW_PRODUCT_REQUESTED = 'SHOW_PRODUCT_REQUESTED';
export const SHOW_PRODUCT_SUCCESS = 'SHOW_PRODUCT_SUCCESS';
export const SHOW_PRODUCT_FAILED = 'SHOW_PRODUCT_FAILED';

export const UPDATE_MOODBOARD_DETAILS_AFTER_SHARING =
  'UPDATE_MOODBOARD_DETAILS_AFTER_SHARING';

//VENDOR ACTIONS
export const GET_BRANDS_REQUESTED = 'GET_BRANDS_REQUESTED';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILED = 'GET_BRANDS_FAILED';

//DESIGNER ACTIONS
export const SAVE_DESIGNER_AVATAR_REQUESTED = 'SAVE_DESIGNER_AVATAR_REQUESTED';
export const SAVE_DESIGNER_AVATAR_SUCCESS = 'SAVE_DESIGNER_AVATAR_SUCCESS';
export const SAVE_DESIGNER_AVATAR_FAILED = 'SAVE_DESIGNER_AVATAR_FAILURE';

export const SAVE_DESIGNER_SIGNATURE_REQUESTED =
  'SAVE_DESIGNER_SIGNATURE_REQUESTED';
export const SAVE_DESIGNER_SIGNATURE_SUCCESS =
  'SAVE_DESIGNER_SIGNATURE_SUCCESS';
export const SAVE_DESIGNER_SIGNATURE_FAILED = 'SAVE_DESIGNER_SIGNATURE_FAILED';

export const REQUEST_DESIGNER_EDIT_REQUESTED =
  'REQUEST_DESIGNER_EDIT_REQUESTED';
export const REQUEST_DESIGNER_EDIT_SUCCESS = 'REQUEST_DESIGNER_EDIT_SUCCESS';
export const REQUEST_DESIGNER_EDIT_FAILED = 'REQUEST_DESIGNER_EDIT_FAILED';

export const GET_DESIGNER_DATA_REQUESTED = 'GET_DESIGNER_DATA_REQUESTED';
export const GET_DESIGNER_DATA_SUCCESS = 'GET_DESIGNER_DATA_SUCCESS';
export const GET_DESIGNER_DATA_FAILED = 'GET_DESIGNER_DATA_FAILED';

export const GET_DESIGNER_STATS_REQUESTED = 'GET_DESIGNER_STATS_REQUESTED';
export const GET_DESIGNER_STATS_SUCCESS = 'GET_DESIGNER_STATS_SUCCESS';
export const GET_DESIGNER_STATS_FAILED = 'GET_DESIGNER_STATS_FAILED';

//3D MOODBOARD ACTIONS
export const CREATE_THREE_D_MOODBOARD_REQUESTED =
  'CREATE_THREE_D_MOODBOARD_REQUESTED';
export const CREATE_THREE_D_MOODBOARD_SUCCESS =
  'CREATE_THREE_D_MOODBOARD_SUCCESS';
export const CREATE_THREE_D_MOODBOARD_FAILED =
  'CREATE_THREE_D_MOODBOARD_FAILED';

export const LIST_THREE_D_MOODBOARD_REQUESTED =
  'LIST_THREE_D_MOODBOARD_REQUESTED';
export const LIST_THREE_D_MOODBOARD_SUCCESS = 'LIST_THREE_D_MOODBOARD_SUCCESS';
export const LIST_THREE_D_MOODBOARD_FAILED = 'LIST_THREE_D_MOODBOARD_FAILED';

export const GET_THREE_D_MOODBOARD_REQUESTED =
  'GET_THREE_D_MOODBOARD_REQUESTED';
export const GET_THREE_D_MOODBOARD_SUCCESS = 'GET_THREE_D_MOODBOARD_SUCCESS';
export const GET_THREE_D_MOODBOARD_FAILED = 'GET_THREE_D_MOODBOARD_FAILED';

export const SHARE_THREE_D_MOODBOARD_REQUESTED =
  'SHARE_THREE_D_MOODBOARD_REQUESTED';
export const SHARE_THREE_D_MOODBOARD_SUCCESS =
  'SHARE_THREE_D_MOODBOARD_SUCCESS';
export const SHARE_THREE_D_MOODBOARD_FAILED = 'SHARE_THREE_D_MOODBOARD_FAILED';

export const DUPLICATE_THREE_D_MOODBOARD_REQUESTED =
  'DUPLICATE_THREE_D_MOODBOARD_REQUESTED';
export const DUPLICATE_THREE_D_MOODBOARD_SUCCESS =
  'DUPLICATE_THREE_D_MOODBOARD_SUCCESS';
export const DUPLICATE_THREE_D_MOODBOARD_FAILED =
  'DUPLICATE_THREE_D_MOODBOARD_FAILED';

export const DELETE_THREE_D_MOODBOARD_REQUESTED =
  'DELETE_THREE_D_MOODBOARD_REQUESTED';
export const DELETE_THREE_D_MOODBOARD_SUCCESS =
  'DELETE_THREE_D_MOODBOARD_SUCCESS';
export const DELETE_THREE_D_MOODBOARD_FAILED =
  'DELETE_THREE_D_MOODBOARD_FAILED';
