import initialState from './initialState';
import { showErrorToast, showSuccessToast } from '../../../utility/showToast';
import {
  CREATE_MOODBOARD_FAILED,
  CREATE_MOODBOARD_REQUESTED,
  CREATE_MOODBOARD_SUCCESS,
  DELETE_MOODBOARD_FAILED,
  DELETE_MOODBOARD_REQUESTED,
  DELETE_MOODBOARD_SUCCESS,
  MOODBOARD_LIST_FAILED,
  MOODBOARD_LIST_REQUESTED,
  MOODBOARD_LIST_SUCCESS,
  MOODBOARD_TEMPLATE_LIST_FAILED,
  MOODBOARD_TEMPLATE_LIST_REQUESTED,
  MOODBOARD_TEMPLATE_LIST_SUCCESS,
  STUDIO_LIST_REQUESTED,
  STUDIO_LIST_SUCCESS,
  STUDIO_LIST_FAILED,
  UPDATE_MOODBOARD_FAILED,
  UPDATE_MOODBOARD_SUCCESS,
  UPDATE_MOODBOARD_REQUESTED,
  DELETE_MOODBOARD_PRODUCT_REQUESTED,
  DELETE_MOODBOARD_PRODUCT_SUCCESS,
  DELETE_MOODBOARD_PRODUCT_FAILED,
  ADD_MOODBOARD_PRODUCT_FAILED,
  ADD_MOODBOARD_PRODUCT_SUCCESS,
  ADD_MOODBOARD_PRODUCT_REQUESTED,
  GET_MOODBOARD_REQUESTED,
  GET_MOODBOARD_SUCCESS,
  GET_MOODBOARD_FAILED,
  DUPLICATE_MOODBOARD_REQUESTED,
  DUPLICATE_MOODBOARD_SUCCESS,
  DUPLICATE_MOODBOARD_FAILED,
  UPDATE_MOODBOARD_LIST,
  UPDATE_PREVIEW_URL_REQUESTED,
  UPDATE_PREVIEW_URL_SUCCESS,
  UPDATE_PREVIEW_URL_FAILED,
  SHARE_TO_CLIENT_REQUESTED,
  SHARE_TO_CLIENT_SUCCESS,
  SHARE_TO_CLIENT_FAILED,
  PUBLISH_TO_CLIENT_REQUESTED,
  PUBLISH_TO_CLIENT_SUCCESS,
  PUBLISH_TO_CLIENT_FAILED,
  ADD_ALTERNATE_PRODUCT_REQUESTED,
  ADD_ALTERNATE_PRODUCT_SUCCESS,
  ADD_ALTERNATE_PRODUCT_FAILED,
  SWAP_ALTERNATE_PRODUCT_REQUESTED,
  SWAP_ALTERNATE_PRODUCT_SUCCESS,
  SWAP_ALTERNATE_PRODUCT_FAILED,
  PRODUCT_INTERACTIVITY_REQUESTED,
  PRODUCT_INTERACTIVITY_SUCCESS,
  PRODUCT_INTERACTIVITY_FAILED,
  UPDATE_MOODBOARD_DETAILS_AFTER_SHARING,
  UPDATE_MOODBOARD_SHARED,
  HIDE_PRODUCT_REQUESTED,
  HIDE_PRODUCT_SUCCESS,
  HIDE_PRODUCT_FAILED,
  SHOW_PRODUCT_FAILED,
  SHOW_PRODUCT_SUCCESS,
  SHOW_PRODUCT_REQUESTED,
  MOODBOARD_RATING_SUCCESS,
  MOODBOARD_RATING_REQUESTED,
  MOODBOARD_RATING_FAILED,
} from '../../statusTypes';

const moodboardReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state });

  switch (action.type) {
    //CREATE MOODBOARD
    case CREATE_MOODBOARD_REQUESTED:
      nextState.moodboard.createMoodboardStatus = CREATE_MOODBOARD_REQUESTED;
      return nextState;
    case CREATE_MOODBOARD_SUCCESS:
      nextState.moodboard.createMoodboardStatus = CREATE_MOODBOARD_SUCCESS;
      nextState.moodboard.moodboardList = [
        action.data,
        ...nextState.moodboard.moodboardList,
      ];

      return nextState;
    case CREATE_MOODBOARD_FAILED:
      nextState.moodboard.createMoodboardStatus = CREATE_MOODBOARD_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //MOODBOARD LIST
    case MOODBOARD_LIST_REQUESTED:
      nextState.moodboard.listStatus = MOODBOARD_LIST_REQUESTED;
      return nextState;

    case MOODBOARD_RATING_REQUESTED:
      nextState.moodboard.ratingStatus = MOODBOARD_RATING_REQUESTED;
      return nextState;

    case MOODBOARD_RATING_SUCCESS:
      nextState.moodboard.ratingStatus = MOODBOARD_RATING_SUCCESS;
      nextState.moodboard.ratings = action.data;
      return nextState;

    case MOODBOARD_RATING_FAILED:
      nextState.moodboard.ratingStatus = MOODBOARD_RATING_FAILED;
      return nextState;

    case MOODBOARD_LIST_SUCCESS:
      nextState.moodboard.listStatus = MOODBOARD_LIST_SUCCESS;
      nextState.moodboard.moodboardList = action.data.results;
      nextState.moodboard.totalRecords = action.data.totalResults;
      nextState.moodboard.totalPages = action.data.totalPages;

      return nextState;
    case MOODBOARD_LIST_FAILED:
      nextState.moodboard.listStatus = MOODBOARD_LIST_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //DELETE MOODBOARD
    case DELETE_MOODBOARD_REQUESTED:
      nextState.moodboard.deleteMoodboardStatus = DELETE_MOODBOARD_REQUESTED;
      return nextState;
    case DELETE_MOODBOARD_SUCCESS:
      nextState.moodboard.deleteMoodboardStatus = DELETE_MOODBOARD_SUCCESS;
      const removeItem = nextState.moodboard.moodboardList.findIndex(
        (e, index) => e.id === action.data.id,
      );
      nextState.moodboard.moodboardList.splice(removeItem, 1);
      return nextState;
    case DELETE_MOODBOARD_FAILED:
      nextState.moodboard.deleteMoodboardStatus = DELETE_MOODBOARD_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //MOODBOARD_TEMPLATE LIST
    case MOODBOARD_TEMPLATE_LIST_REQUESTED:
      nextState.moodboardTemplate.moodboardTemplateListStatus =
        MOODBOARD_TEMPLATE_LIST_REQUESTED;
      return nextState;
    case MOODBOARD_TEMPLATE_LIST_SUCCESS:
      nextState.moodboardTemplate.moodboardTemplateListStatus =
        MOODBOARD_TEMPLATE_LIST_SUCCESS;
      nextState.moodboardTemplate.moodboardTemplates = action.data;
      return nextState;
    case MOODBOARD_TEMPLATE_LIST_FAILED:
      nextState.moodboardTemplate.moodboardTemplateListStatus =
        MOODBOARD_TEMPLATE_LIST_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //STUDIO LIST
    case STUDIO_LIST_REQUESTED:
      nextState.moodboard.studioListStatus = STUDIO_LIST_REQUESTED;
      return nextState;
    case STUDIO_LIST_SUCCESS:
      nextState.moodboard.studioListStatus = STUDIO_LIST_SUCCESS;
      nextState.moodboard.studioList = action.data;
      return nextState;
    case STUDIO_LIST_FAILED:
      nextState.moodboard.studioListStatus = STUDIO_LIST_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //UPDATE MOODBOARD LIST
    case UPDATE_MOODBOARD_REQUESTED:
      nextState.moodboard.updateMoodboardStatus = UPDATE_MOODBOARD_REQUESTED;
      return nextState;
    case UPDATE_MOODBOARD_SUCCESS:
      nextState.moodboard.updateMoodboardStatus = UPDATE_MOODBOARD_SUCCESS;
      const editedMoodboard = nextState.moodboard.moodboardList.findIndex(
        (o1) => o1.id === action.data.id,
      );
      nextState.moodboard.moodboardList[editedMoodboard] = action.data;
      nextState.moodboard.moodboard = action.data;
      showSuccessToast({
        title: 'Success',
        description: 'Updated Successfully',
      });
      return nextState;
    case UPDATE_MOODBOARD_FAILED:
      nextState.moodboard.updateMoodboardStatus = UPDATE_MOODBOARD_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;
    case UPDATE_MOODBOARD_LIST:
      const editMoodboard = nextState.moodboard.moodboardList.findIndex(
        (o1) => o1.id === action.data.id,
      );
      nextState.moodboard.moodboardList[editMoodboard].title =
        action.data.title;
      nextState.moodboard.moodboardList[editMoodboard].styles = [
        ...action.data.styles,
      ];
      return nextState;

    //ADD MOODBOARD PRODUCT
    case ADD_MOODBOARD_PRODUCT_REQUESTED:
      nextState.moodboard.addMoodboardProductStatus =
        ADD_MOODBOARD_PRODUCT_REQUESTED;
      return nextState;
    case ADD_MOODBOARD_PRODUCT_SUCCESS:
      nextState.moodboard.addMoodboardProductStatus =
        ADD_MOODBOARD_PRODUCT_SUCCESS;
      const moodboard = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      moodboard[0].products = action.data.moodboard.products;
      /* if (nextState.moodboard.studioList.length && action.data.products.length) {
                nextState.moodboard.studioList = [...nextState.moodboard.studioList, ...action.data.products]
            } else if (action.data.products.length) { */
      nextState.moodboard.studioList = action.data.products;
      //}
      return nextState;
    case ADD_MOODBOARD_PRODUCT_FAILED:
      nextState.moodboard.addMoodboardProductStatus =
        ADD_MOODBOARD_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //DELETE MOODBOARD PRODUCT
    case DELETE_MOODBOARD_PRODUCT_REQUESTED:
      nextState.moodboard.deleteMoodboardProductStatus =
        DELETE_MOODBOARD_PRODUCT_REQUESTED;
      return nextState;
    case DELETE_MOODBOARD_PRODUCT_SUCCESS:
      nextState.moodboard.deleteMoodboardProductStatus =
        DELETE_MOODBOARD_PRODUCT_SUCCESS;
      const currentMoodboard = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      currentMoodboard[0].products = action.data.moodboard.products;
      return nextState;
    case DELETE_MOODBOARD_PRODUCT_FAILED:
      nextState.moodboard.deleteMoodboardProductStatus =
        DELETE_MOODBOARD_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;
    case GET_MOODBOARD_REQUESTED:
      nextState.moodboard.getMoodboardStatus = GET_MOODBOARD_REQUESTED;
      return nextState;
    case GET_MOODBOARD_SUCCESS:
      nextState.moodboard.getMoodboardStatus = GET_MOODBOARD_SUCCESS;
      nextState.moodboard.moodboard = action.data;
      /* if (nextState.moodboard.studioList.length && action.data.productsList.length) {
                nextState.moodboard.studioList = [...nextState.moodboard.studioList, ...action.data.productsList]
            } else if (action.data.productsList.length) { */
      nextState.moodboard.studioList = action.data.productsList;
      //}
      return nextState;
    case GET_MOODBOARD_FAILED:
      nextState.moodboard.getMoodboardStatus = GET_MOODBOARD_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //DUPLICATE MOODBOARD
    case DUPLICATE_MOODBOARD_REQUESTED:
      nextState.moodboard.duplicateMoodboardStatus =
        DUPLICATE_MOODBOARD_REQUESTED;
      return nextState;
    case DUPLICATE_MOODBOARD_SUCCESS:
      nextState.moodboard.duplicateMoodboardStatus =
        DUPLICATE_MOODBOARD_SUCCESS;
      nextState.moodboard.moodboardList = [
        action.data,
        ...nextState.moodboard.moodboardList,
      ];
      return nextState;
    case DUPLICATE_MOODBOARD_FAILED:
      nextState.moodboard.duplicateMoodboardStatus = DUPLICATE_MOODBOARD_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //REQUEST 3D MODEL
    case UPDATE_PREVIEW_URL_REQUESTED:
      nextState.moodboard.updatePreviewUrlStatus = UPDATE_PREVIEW_URL_REQUESTED;
      return nextState;
    case UPDATE_PREVIEW_URL_SUCCESS:
      nextState.moodboard.updatePreviewUrlStatus = UPDATE_PREVIEW_URL_SUCCESS;
      return nextState;
    case UPDATE_PREVIEW_URL_FAILED:
      nextState.moodboard.updatePreviewUrlStatus = UPDATE_PREVIEW_URL_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //SHARE TO CLIENT
    case SHARE_TO_CLIENT_REQUESTED:
      nextState.requestToClientStatus = SHARE_TO_CLIENT_REQUESTED;
      return nextState;
    case SHARE_TO_CLIENT_SUCCESS:
      nextState.requestToClientStatus = SHARE_TO_CLIENT_SUCCESS;
      showSuccessToast({
        title: 'Success',
        description: `Successfully shared with client`,
      });
      return nextState;
    case SHARE_TO_CLIENT_FAILED:
      nextState.requestToClientStatus = SHARE_TO_CLIENT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //PUBLISH TO CLIENT
    case PUBLISH_TO_CLIENT_REQUESTED:
      nextState.publishToClientStatus = PUBLISH_TO_CLIENT_REQUESTED;
      return nextState;
    case PUBLISH_TO_CLIENT_SUCCESS:
      nextState.publishToClientStatus = PUBLISH_TO_CLIENT_SUCCESS;
      showSuccessToast({
        title: 'Success',
        description: `Successfully published with client`,
      });
      return nextState;
    case PUBLISH_TO_CLIENT_FAILED:
      nextState.publishToClientStatus = PUBLISH_TO_CLIENT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //ADD ALTERNATE PRODUCT
    case ADD_ALTERNATE_PRODUCT_REQUESTED:
      nextState.moodboard.addAlternateProductStatus =
        ADD_ALTERNATE_PRODUCT_REQUESTED;
      return nextState;
    case ADD_ALTERNATE_PRODUCT_SUCCESS:
      nextState.moodboard.addAlternateProductStatus =
        ADD_ALTERNATE_PRODUCT_SUCCESS;
      const moodboardList = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      moodboardList[0].products = action.data.moodboard.products;
      /* if (nextState.moodboard.studioList.length && action.data.products.length) {
                nextState.moodboard.studioList = [...nextState.moodboard.studioList, ...action.data.products]
            } else if (action.data.products.length) { */
      nextState.moodboard.studioList = action.data.products;
      //}
      return nextState;
    case ADD_ALTERNATE_PRODUCT_FAILED:
      nextState.moodboard.addAlternateProductStatus =
        ADD_ALTERNATE_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //SWAP ALTERNATE PRODUCT
    case SWAP_ALTERNATE_PRODUCT_REQUESTED:
      nextState.moodboard.swapAlternateProductStatus =
        SWAP_ALTERNATE_PRODUCT_REQUESTED;
      return nextState;
    case SWAP_ALTERNATE_PRODUCT_SUCCESS:
      nextState.moodboard.swapAlternateProductStatus =
        SWAP_ALTERNATE_PRODUCT_SUCCESS;
      const moodboardProducts = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      moodboardProducts[0].products = action.data.moodboard.products;
      /* if (nextState.moodboard.studioList.length && action.data.products.length) {
                nextState.moodboard.studioList = [...nextState.moodboard.studioList, ...action.data.products]
            } else if (action.data.products.length) { */
      nextState.moodboard.studioList = action.data.products;
      //}
      return nextState;
    case SWAP_ALTERNATE_PRODUCT_FAILED:
      nextState.moodboard.swapAlternateProductStatus =
        SWAP_ALTERNATE_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;
    //PRODUCT INTERACTIVITY
    case PRODUCT_INTERACTIVITY_REQUESTED:
      nextState.moodboard.productInteractivityStatus =
        PRODUCT_INTERACTIVITY_REQUESTED;
      return nextState;
    case PRODUCT_INTERACTIVITY_SUCCESS:
      nextState.moodboard.productInteractivityStatus =
        PRODUCT_INTERACTIVITY_SUCCESS;
      const allMoodboard = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      allMoodboard[0].products = action.data.moodboard.products;
      nextState.moodboard.studioList = action.data.products;
      return nextState;
    case PRODUCT_INTERACTIVITY_FAILED:
      nextState.moodboard.productInteractivityStatus =
        PRODUCT_INTERACTIVITY_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //SHOW PRODUCT
    case SHOW_PRODUCT_REQUESTED:
      nextState.moodboard.showProductStatus = SHOW_PRODUCT_REQUESTED;
      return nextState;
    case SHOW_PRODUCT_SUCCESS:
      nextState.moodboard.showProductStatus = SHOW_PRODUCT_SUCCESS;
      const allMoodboards = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      allMoodboards[0].products = action.data.moodboard.products;
      nextState.moodboard.studioList = action.data.products;
      return nextState;
    case SHOW_PRODUCT_FAILED:
      nextState.moodboard.showProductStatus = SHOW_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //HIDE PRODUCT
    case HIDE_PRODUCT_REQUESTED:
      nextState.moodboard.hideProductStatus = HIDE_PRODUCT_REQUESTED;
      return nextState;
    case HIDE_PRODUCT_SUCCESS:
      nextState.moodboard.hideProductStatus = HIDE_PRODUCT_SUCCESS;
      const allMoodboardList = nextState.moodboard.moodboardList.filter(
        (o1) => o1.id === action.data.moodboard.id,
      );
      allMoodboardList[0].products = action.data.moodboard.products;
      nextState.moodboard.studioList = action.data.products;
      return nextState;
    case HIDE_PRODUCT_FAILED:
      nextState.moodboard.hideProductStatus = HIDE_PRODUCT_FAILED;
      showErrorToast({
        title: 'Error',
        description: action.error.message,
      });
      return nextState;

    //UPDATE THE MOODBOARD STATUS AFTER UPDATING
    case UPDATE_MOODBOARD_DETAILS_AFTER_SHARING:
      const updatedMoodboard = nextState.moodboard.moodboardList.map(
        (item, key) => {
          if (action.data.moodboard.includes(item.id)) {
            item.status = action.data.project.job;
            return item;
          } else {
            return item;
          }
        },
      );
      nextState.moodboard.moodboardList = updatedMoodboard;
      return nextState;

    //UPDATE THE STATUS OF THE NEW MOODBOARD AND CHANGE THE STATUS OF ALREADY SHARED MOODBOARD
    case UPDATE_MOODBOARD_SHARED:
      const updateMoodboard = nextState.moodboard.moodboardList.map(
        (item, key) => {
          if (item.id === action.data.moodboard) {
            item.status = action.data.project.job;
            return item;
          } else if (item.status === action.data.project.job) {
            item.status = 'draft';
            return item;
          } else {
            return item;
          }
        },
      );
      nextState.moodboard.moodboardList = updateMoodboard;
      return nextState;

    default:
      return nextState;
  }
};

export default moodboardReducer;
