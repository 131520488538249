import {
  AUTH_FAILED,
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_FORGOT_PASSWORD,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED
} from "../../statusTypes";
import initialState from "./initialState";
import { showSuccessToast, showErrorToast } from "../../../utility/showToast"

const authReducer = (state = initialState, action) => {

  const nextState = Object.assign({}, { ...state })

  switch (action.type) {
    case AUTH_REQUESTED:
      nextState.status = AUTH_REQUESTED
      return nextState
    case AUTH_SUCCESS:
      nextState.currentSessionValidated = true;
      nextState.isAuthenticated = true;
      nextState.currentUser = action.data;
      nextState.login.data = Object.assign({}, action.data.designer)
      nextState.status = AUTH_SUCCESS
      return nextState;

    case AUTH_FAILED:
      nextState.currentSessionValidated = true;
      nextState.isAuthenticated = false;
      nextState.currentUser = null;
      nextState.status = AUTH_FAILED
      nextState.login = Object.assign({}, state.login)
      return nextState

    // LOGIN REDUCER
    case LOGIN_REQUESTED:
      nextState.login.status = LOGIN_REQUESTED
      return nextState
    case LOGIN_SUCCESS:
      nextState.login = {
        ...nextState.login,
        status: LOGIN_SUCCESS,
        data: action.data,
        error: null
      }
      showSuccessToast({
        title: 'Success',
        description: `Welcome,  ${action.data.first_name} ${action.data.last_name}`
      })
      return nextState
    case LOGIN_FAILED:
      nextState.login = {
        ...nextState.login,
        status: LOGIN_FAILED,
        data: {},
        error: action.error
      }
      showErrorToast({
        title: 'Error',
        description: (action && action.error.message) || 'something went wrong'
      })
      return nextState

    //FORGOT PASSWORD REDUCER
    case FORGOT_PASSWORD_REQUESTED:
      nextState.password.status = FORGOT_PASSWORD_REQUESTED
      return nextState
    case FORGOT_PASSWORD_SUCCESS:
      showSuccessToast({
        title: 'Success',
        description: 'Password Successfully updated'
      })
      nextState.password.status = FORGOT_PASSWORD_SUCCESS
      return nextState
    case FORGOT_PASSWORD_FAILED:
      nextState.password.status = FORGOT_PASSWORD_FAILED
      showErrorToast({
        title: 'Error',
        description: (action && action.error.message) || 'something went wrong'
      })
      return nextState
    case RESET_PASSWORD_REQUESTED:
      nextState.password.resetStatus = RESET_PASSWORD_REQUESTED
      return nextState
    case RESET_PASSWORD_SUCCESS:
      nextState.password.resetStatus = RESET_PASSWORD_SUCCESS
      return nextState
    case RESET_PASSWORD_FAILED:
      nextState.password.resetStatus = RESET_PASSWORD_FAILED
      showErrorToast({
        title: 'Error',
        description: (action && action.error.message) || 'something went wrong'
      })
      return nextState
    case RESET_FORGOT_PASSWORD:
      nextState.password.status = ''
      return nextState

    default:
      return nextState
  }
}

export default authReducer