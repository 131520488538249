import { combineReducers } from "redux";
import projectReducer from "./app/projects";
import authReducer from "./auth";
import layoutReducer from "./layout";
import productLayoutReducer from "./productLayout"
import projectAndTabActionsReducer from './projectAndTabActions'
import productReducer from "./products";
import noteReducer from "./notes"
import moodboardReducer from "./twoDMoodboard";
import vendorReducer from "./vendor";
import designerReducer from "./designer";
import threeDMoodboardReducer from "./threeDMoodboard"

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  productLayout: productLayoutReducer,
  project: projectAndTabActionsReducer,
  projects: projectReducer,
  products: productReducer,
  notes: noteReducer,
  twoDMoodboard: moodboardReducer,
  vendor: vendorReducer,
  designer: designerReducer,
  threeDMoodboard: threeDMoodboardReducer
})

export default rootReducer;