export default {
    moodboardTemplate: {
        moodboardTemplates: [],
        moodboardTemplateListStatus: null
    },
    moodboard: {
        moodboardList: [],
        studioList: [],
        listStatus: null,
        createMoodboardStatus: null,
        deleteMoodboardStatus: null,
        studioListStatus: null,
        updateMoodboardStatus: null,
        addMoodboardProductStatus: null,
        deleteMoodboardProductStatus: null,
        moodboard: {},
        getMoodboardStatus: null,
        duplicateMoodboardStatus: null,
        updatePreviewUrlStatus: null,
        requestToClientStatus: null,
        publishToClientStatus: null,
        addAlternateProductStatus: null,
        swapAlternateProductStatus: null,
        productInteractivityStatus: null,
        showProductStatus: null,
        hideProductStatus: null,
        ratings: [],
        ratingStatus: null
    }
}