export default {
  // INCOMING PROJECT LIST STATE
  incomingProjectListStatus: null,
  updateStatus: null,
  incomingProjects: [],
  acceptProjectStatus: null,
  rejectProjectStatus: null,

  //LIST PROJECT STATE
  listStatus: null,
  projects: [],

  // SINGLE PROJECT STATE
  detailStatus: null,
  selectedProject: {},
  shareMoodboardStatus: null,
  reviewSharedMoodboardStatus: null
}