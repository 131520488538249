export default {
  moodboard: {
    createMoodboardStatus: null,
    listStatus: null,
    moodboardList: [],
    publishSpaceStatus: null,
    get3DMoodboardStatus: null,
    threeDMoodboard: {},
    duplicateMoodboardStatus: null,
  }
}