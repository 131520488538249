import {
    HOME_TAB_ACTION,
    PROJECT_DETAILS_TAB_ACTION,
    SETTINGS_TAB_ACTION
} from "../../statusTypes";
import initialState from "./initialState";

const projectAndTabActionsReducer = (state = initialState, action) => {
    const nextState = Object.assign({}, { ...state })

    switch (action.type) {
        case HOME_TAB_ACTION:
            nextState.tab.home = action.data.tab
            return nextState
        case PROJECT_DETAILS_TAB_ACTION:
            nextState.tab.projectDetails = action.data.tab
        case SETTINGS_TAB_ACTION:
            nextState.tab.settings = action.data.tab
        default:
            return nextState
    }
}
export default projectAndTabActionsReducer